<template>
        <v-container  justify-center>
        <v-layout
          justify-center
          align-center column
          style="background-color:rgb(115, 114, 112,0.3); margin-top:50px"
        >    
     <v-img

style="margin-top:50px;  height: auto; object-fit:none;"
  max-width="200"
  src="../assets/Skattedagen_logo2023.png"
></v-img>
      <v-col md="6">
  
    <v-row align="center" style="margin-top:40px;"
      justify="center">
      <v-col> <v-btn style="margin-right:40px" v-if="!user" class="white--text text--lighten-3"
    
  
    color="#df8639"
  
  elevation="2"
    to="/loggain"
>Logga in</v-btn>

<v-btn v-if="!user" class="white--text text--lighten-3"
    

    color="#df8639"
    to="registrera"
  
  elevation="2"
>Registrera</v-btn></v-col>
  </v-row>
 <v-row no-gutters style="margin-top:40px; ">
  <v-col md="3" xs="12">


  


    </v-col>
    
      <v-col md="6" xs="12">
           
    </v-col>
   
 </v-row>
 
    </v-col>
   
    
       
      

        </v-layout>
      </v-container>
</template>

<script>
// @ is an alias to /src

import {mapState} from 'vuex'

export default {
  name: 'Home',
  components: {
  },
   computed: {
    ...mapState(['user']),
    displayName () {
      return !this.user ? this.user.email : '';
    }
    
  },
    mounted() {
      this.$emit("showAppBar", true);
  },
   watch: {
    user (auth) {
      if(auth){
        this.$router.replace("meny")
      }
    }},
}
</script>
