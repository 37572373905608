import store from "../store";
import Firebase from "firebase";

const config = {
  apiKey: "AIzaSyCVCIiXFN167DivYiu_y1ejaZhdmLxxvkE",
  authDomain: "bdoevent-c84f1.firebaseapp.com",
  databaseURL: "https://bdoevent-c84f1.firebaseio.com",
  projectId: "bdoevent-c84f1",
  storageBucket: "bdoevent-c84f1.appspot.com",
  messagingSenderId: "266830274253"
};
const firebase = Firebase.initializeApp(config);
const auth = firebase.auth();
export const db = firebase.firestore();

export default {
  install: (Vue) => {
    db.settings({
      timestampsInSnapshots: true,
    });

    Vue.prototype.$auth = {
      login: async (username, pass) => {
        return auth.signInWithEmailAndPassword(username, pass);
      },
      logout: async () => {
        console.log("logout");
        localStorage.removeItem("user");
        store.commit("deleteUser");
        await auth.signOut();
      },
      register: async (email, password) => {
        return await auth.createUserWithEmailAndPassword(email, password);
      },
      addUserRecord: async (data) => {
        console.log("addUserRecord", auth.currentUser.uid);
        db.collection("users")
          .doc(auth.currentUser.uid)
          .set(data);
      },
      updateUserFinishedCategory: async (categoryId, answers) => {
        console.log("updateUserScore", auth.currentUser.uid, answers);
        db.collection("users")
          .doc(auth.currentUser.uid)
          .update({
            finishedCourses: Firebase.firestore.FieldValue.arrayUnion(
              categoryId
            ),
            ["Answers" + categoryId]: answers,
            [`finishedCategory.${categoryId}`]:{ time: Date.now(), categoryId: categoryId },
          });
      },
      updateUserWatchTime: async (categoryId, seconds) => {
        console.log("updateUserWatchTime", auth.currentUser.uid, seconds)
        db.collection("users").doc(auth.currentUser.uid).update({
          watchTime: Firebase.firestore.FieldValue.arrayUnion(categoryId),
          ['WatchTime'+categoryId]: seconds
      })},
      updateUserCompleted: async () => {
        console.log("updated");
        db.collection("users")
          .doc(auth.currentUser.uid)
          .update({
            completed: true,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
      },
      getUserRecord: async () => {
        return await db
          .collection("users")
          .doc(auth.currentUser.uid)
          .get();
      },
      forgotEmail: async (email) => {
        return await auth.sendPasswordResetEmail(email);
      },
    };

    auth.onAuthStateChanged((user) => {
      store.commit("updateUser", { user });
    });
  },
};
